// app/javascript/controllers/flash_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.fadeOut()
  }

  fadeOut() {
    setTimeout(() => {
      this.element.classList.add('opacity-0')
      setTimeout(() => {
        this.element.remove()
      }, 1000)
    }, 5000)
  }

  close() {
    this.element.remove()
  }
}