import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["imagePreview"]

  handleImageUpload(event) {
    const files = Array.from(event.target.files)
    files.forEach(file => {
      this.uploadFile(file)
    })
  }

  uploadFile(file) {
    const url = this.element.dataset.directUploadUrl
    const upload = new DirectUpload(file, url)

    upload.create((error, blob) => {
      if (error) {
        console.error('Error uploading file:', error)
      } else {
        // File uploaded successfully
        // You could refresh the preview area here if needed
        this.refreshImagePreview()
      }
    })
  }

  refreshImagePreview() {
    // Optional: Refresh the image preview area using Turbo
    Turbo.visit(window.location.href, { action: "replace" })
  }
}