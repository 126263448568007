import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Add any custom Trix initialization here
    this.configureTrixToolbar()
  }

  configureTrixToolbar() {
    // Example: Customize toolbar if needed
    addEventListener("trix-initialize", event => {
      const toolbar = event.target.toolbarElement
      // You can customize the toolbar here
      toolbar.classList.add("bg-space-800", "border-space-700")
    })

    // Handle file attachments (if needed)
    addEventListener("trix-attachment-add", event => {
      // Handle file uploads
    })
  }
}