import React from 'react'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { Info } from "lucide-react"

interface Warehouse {
  id: number
  name: string
}

interface Stock {
  warehouse: Warehouse
  available: number
  reserved: number
  backordered: number
}

interface Item {
  stock: Stock[]
}

interface WarehouseStockProps {
  item: Item
}

const WarehouseStock: React.FC<WarehouseStockProps> = ({ item }) => {
  // Calculate total available stock
  const totalAvailable = item.stock?.reduce((sum, stock) => sum + stock.available, 0) || 0

  return (
    (item.stock && item.stock.length > 0) && (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault()}
            onPointerDown={(e: React.PointerEvent<HTMLButtonElement>) => e.preventDefault()}
            className="px-4 h-10 inline-flex items-center gap-2 rounded-md border border-space-700 text-sm bg-space-800"
          >
            Stock: {totalAvailable}
            <Info className="h-4 w-4 text-space-400" />
          </TooltipTrigger>
          <TooltipContent side="bottom" className="w-auto p-0">
            <div className="bg-space-700 border-0 rounded-md p-2 space-y-1">
              {item.stock.map((stock) => (
                <div key={stock.warehouse.id} className="whitespace-nowrap text-sm">
                  {stock.warehouse.name}
                  <span className="text-space-400 ml-2">
                    AV: {stock.available} | R: {stock.reserved} | BO: {stock.backordered}
                  </span>
                </div>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  )
}

export default WarehouseStock