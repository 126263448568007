import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Initialize drag and drop functionality
    this.initializeDragAndDrop()
  }

  preview(event) {
    const input = event.target
    if (input.files && input.files[0]) {
      // Handle image preview before upload
      this.previewFiles(input.files)
    }
  }

  moveLeft(event) {
    const imageId = event.currentTarget.dataset.imageId
    // Implement reordering logic
    this.reorderImage(imageId, 'left')
  }

  moveRight(event) {
    const imageId = event.currentTarget.dataset.imageId
    // Implement reordering logic
    this.reorderImage(imageId, 'right')
  }

  initializeDragAndDrop() {
    const dropZone = this.element.querySelector('.drop-zone')
    if (!dropZone) return

    dropZone.addEventListener('dragover', (e) => {
      e.preventDefault()
      dropZone.classList.add('border-space-500')
    })

    dropZone.addEventListener('dragleave', (e) => {
      e.preventDefault()
      dropZone.classList.remove('border-space-500')
    })

    dropZone.addEventListener('drop', (e) => {
      e.preventDefault()
      dropZone.classList.remove('border-space-500')
      const files = e.dataTransfer.files
      if (files.length > 0) {
        this.element.querySelector('input[type="file"]').files = files
        this.previewFiles(files)
      }
    })
  }

  previewFiles(files) {
    // Implement preview functionality
  }

  async reorderImage(imageId, direction) {
    // Implement reordering functionality via AJAX
    try {
      const response = await fetch(`/ox/${this.subdomain}/products/${this.productId}/reorder_image`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          image_id: imageId,
          direction: direction
        })
      })

      if (response.ok) {
        // Refresh the image gallery
        location.reload()
      }
    } catch (error) {
      console.error('Error reordering image:', error)
    }
  }
}