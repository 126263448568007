import React, { useState, useRef, useCallback, useEffect } from 'react'
import { Customer, Address, AddressData } from '../../dataTypes'
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "@/components/ui/dialog"
import { UserSearch, X, Plus, Edit } from "lucide-react"
import { DialogDescription } from '@radix-ui/react-dialog'
import { Label } from "@/components/ui/label"

interface CustomerManagementProps {
  onCustomerSelect: (customer: Customer | null) => void
  selectedCustomer: Customer | null
  editMode: boolean
}

interface CustomerFromSearch extends Omit<Customer, 'addresses'> {
  addresses: Address[]
}

const CustomerManagement: React.FC<CustomerManagementProps> = ({
  onCustomerSelect,
  selectedCustomer,
  editMode
}) => {
  const [searchDialogOpen, setSearchDialogOpen] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [searchError, setSearchError] = useState<string | null>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [customerSuggestions, setCustomerSuggestions] = useState<CustomerFromSearch[]>([])
  const [editingAddress, setEditingAddress] = useState<{ address: Address, type: keyof AddressData } | null>(null)
  const [editedAddress, setEditedAddress] = useState<Address | null>(null)
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false)
  const [newAddressType, setNewAddressType] = useState<keyof AddressData>('billing')

  const searchInputRef = useRef<HTMLInputElement>(null)

  const handleSearch = useCallback(async () => {
    if (searchQuery.length < 2) {
      setSearchError('Please enter at least 2 characters to search')
      return
    }

    setIsSearching(true)
    setSearchError(null)
    try {
      const response = await fetch(`/api/v1/customers/search?query=${encodeURIComponent(searchQuery)}`)
      if (response.ok) {
        const data = await response.json()
        setCustomerSuggestions(data || [])
      } else {
        throw new Error('Failed to fetch customer suggestions')
      }
    } catch (error) {
      console.error('Error searching for customers:', error)
      setSearchError('An error occurred while searching for customers. Please try again.')
      setCustomerSuggestions([])
    } finally {
      setIsSearching(false)
    }
  }, [searchQuery])

  useEffect(() => {
    if (searchQuery.length >= 2) {
      const debounceTimer = setTimeout(() => {
        handleSearch()
      }, 300)

      return () => clearTimeout(debounceTimer)
    }
  }, [searchQuery, handleSearch])

  const handleCustomerSelect = (customer: CustomerFromSearch) => {
    setSearchDialogOpen(false)

    // Convert array of addresses to AddressData structure
    let billingAddress = customer.addresses.find(addr => addr.isBilling)
    let shippingAddress = customer.addresses.find(addr => addr.isShipping)

    // If no explicitly marked addresses are found, use first address for both or null
    if (!billingAddress && !shippingAddress && customer.addresses.length > 0) {
      billingAddress = customer.addresses[0]
      shippingAddress = customer.addresses[0]
    }

    const formattedCustomer: Customer = {
      ...customer,
      addresses: {
        billing: billingAddress || null,
        shipping: shippingAddress || null
      }
    }

    onCustomerSelect(formattedCustomer)
  }

  const handleRemoveCustomer = () => {
    onCustomerSelect(null)
  }

  const handleEditAddress = (e: React.MouseEvent, address: Address, type: keyof AddressData) => {
    e.preventDefault()
    setEditingAddress({ address, type })
    setEditedAddress({ ...address })
  }

  const handleAddressInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editedAddress) {
      setEditedAddress({
        ...editedAddress,
        [e.target.name]: e.target.value
      })
    }
  }

  const handleAddNewAddress = () => {
    setIsAddingNewAddress(true)
    setEditedAddress({
      name: '',
      email: '',
      address_1: '',
      address_2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      phone: '',
      fax: '',
      isBilling: newAddressType === 'billing',
      isShipping: newAddressType === 'shipping'
    })
  }

  const handleSaveNewAddress = () => {
    if (!selectedCustomer || !editedAddress) return

    const updatedCustomer: Customer = {
      ...selectedCustomer,
      addresses: {
        ...selectedCustomer.addresses,
        [newAddressType]: {
          ...editedAddress,
          isBilling: newAddressType === 'billing',
          isShipping: newAddressType === 'shipping'
        }
      }
    }

    onCustomerSelect(updatedCustomer)
    setIsAddingNewAddress(false)
    setEditedAddress(null)
  }

  const handleSaveAddress = () => {
    if (!editingAddress || !selectedCustomer || !editedAddress) return

    const updatedCustomer: Customer = {
      ...selectedCustomer,
      addresses: {
        ...selectedCustomer.addresses,
        [editingAddress.type]: {
          ...editedAddress,
          isBilling: editingAddress.type === 'billing',
          isShipping: editingAddress.type === 'shipping'
        }
      }
    }

    onCustomerSelect(updatedCustomer)
    setEditingAddress(null)
    setEditedAddress(null)
  }

  const renderAddressSelector = (type: keyof AddressData) => {
    if (!selectedCustomer) return null

    const address = selectedCustomer.addresses[type]

    return (
      <div className="mb-4">
        <h3 className="font-semibold mb-2">{type === 'billing' ? 'Billing Address' : 'Shipping Address'}</h3>
        {address ? (
          <div className="bg-space-700 p-4 rounded-md">
            <p>{address.name || 'N/A'}</p>
            <p>{address.email || 'N/A'}</p>
            <p>{address.address_1 || 'N/A'}</p>
            {address.address_2 && <p>{address.address_2}</p>}
            <p>{`${address.city || 'N/A'}, ${address.state || 'N/A'} ${address.zip || 'N/A'}`}</p>
            <p>{address.country || 'N/A'}</p>
            {address.phone && <p>Phone: {address.phone}</p>}
            {address.fax && <p>Fax: {address.fax}</p>}
            {editMode && (
              <div className="flex gap-2 mt-2">
                <Button onClick={(e) => handleEditAddress(e, address, type)}>
                  <Edit size={16} className="mr-2" />
                  Edit Address
                </Button>
              </div>
            )}
          </div>
        ) : (
          <Button onClick={(e) => {
            e.preventDefault()
            setNewAddressType(type)
            handleAddNewAddress()
          }} className="w-full">
            <Plus size={16} className="mr-2" />
            Add {type === 'billing' ? 'Billing' : 'Shipping'} Address
          </Button>
        )}
      </div>
    )
  }

  return (
    <section className="w-full">
      {selectedCustomer ? (
        <div className="bg-space-800 p-4 rounded-md">
          <div className="flex flex-col gap-1 mb-4">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-bold">
                <a href={`https://onyx.ktappliance.com/ox/ktapp/customers/${selectedCustomer.id}`} target="_blank" rel="noopener noreferrer">
                  {selectedCustomer.name}
                </a>
              </h2>
              {editMode && (
                <Button onClick={(e) => { e.preventDefault(); handleRemoveCustomer() }} variant="destructive" size="sm">
                  <X size={16} />
                </Button>
              )}
            </div>
            <p className="text-sm">{selectedCustomer.email}</p>
            <p className="">{selectedCustomer.phone}</p>
          </div>
          <div className="flex flex-col gap-1">
            {renderAddressSelector('billing')}
            {renderAddressSelector('shipping')}
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <Dialog open={searchDialogOpen} onOpenChange={setSearchDialogOpen}>
            <DialogTrigger asChild>
              <Button onClick={(e) => { e.preventDefault(); setSearchDialogOpen(true) }} className="bg-green-500 text-white font-bold hover:bg-green-400 transition-all flex-1">
                <UserSearch className="mr-2" />
                Search for a customer
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Customer Search</DialogTitle>
                <DialogDescription>Search the database for a customer to add to this order</DialogDescription>
              </DialogHeader>
              <div>
                <div className="flex gap-2">
                  <Input
                    ref={searchInputRef}
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search for a customer via email or name"
                    autoComplete="off"
                    className="flex-1"
                  />
                  <Button onClick={(e) => { e.preventDefault(); handleSearch() }} disabled={isSearching}>
                    {isSearching ? 'Searching...' : 'Search'}
                  </Button>
                </div>
                {searchError && <p className="mt-2 text-red-500">{searchError}</p>}
                {!isSearching && customerSuggestions.length > 0 && (
                  <div className="mt-4 max-h-60 overflow-y-auto">
                    {customerSuggestions.map((customer, index) => (
                      <div
                        key={index}
                        className="p-2 hover:bg-space-700 cursor-pointer"
                        onClick={() => handleCustomerSelect(customer)}
                      >
                        <div className="font-semibold">{customer.name}</div>
                        <div className="text-sm text-space-300">{customer.email}</div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      )}

      <Dialog open={editingAddress !== null} onOpenChange={(open) => {
        if (!open) {
          setEditingAddress(null)
          setEditedAddress(null)
        }
      }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit {editingAddress?.type === 'billing' ? 'Billing' : 'Shipping'} Address</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {editedAddress && Object.entries(editedAddress).map(([key, value]) => (
              key !== 'id' && key !== 'isBilling' && key !== 'isShipping' && (
                <div key={key} className="flex flex-col gap-4">
                  <Label htmlFor={key}>
                    {key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}
                  </Label>
                  <Input
                    id={key}
                    name={key}
                    value={value as string}
                    onChange={handleAddressInputChange}
                    className="w-full"
                  />
                </div>
              )
            ))}
          </div>
          <DialogFooter>
            <Button onClick={handleSaveAddress}>Save changes</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={isAddingNewAddress} onOpenChange={(open) => {
        if (!open) {
          setIsAddingNewAddress(false)
          setEditedAddress(null)
        }
      }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New {newAddressType === 'billing' ? 'Billing' : 'Shipping'} Address</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {editedAddress && Object.entries(editedAddress).map(([key, value]) => (
              key !== 'id' && key !== 'isBilling' && key !== 'isShipping' && (
                <div key={key} className="flex flex-col gap-4">
                  <Label htmlFor={key}>
                    {key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}
                  </Label>
                  <Input
                    id={key}
                    name={key}
                    value={value as string}
                    onChange={handleAddressInputChange}
                    className="w-full"
                  />
                </div>
              )
            ))}
          </div>
          <DialogFooter>
            <Button onClick={handleSaveNewAddress}>Add Address</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </section>
  )
}

export default CustomerManagement