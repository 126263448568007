import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]

  connect() {
    // Show the first tab by default
    this.showTab(0)
  }

  change(event) {
    // Get the index from the clicked tab
    const clickedTab = event.currentTarget
    const index = this.tabTargets.indexOf(clickedTab)
    this.showTab(index)
  }

  showTab(index) {
    this.tabTargets.forEach((tab, i) => {
      // Toggle active state for tabs
      tab.classList.toggle("bg-space-700", i === index)
      tab.classList.toggle("text-[#E3E3E3]", i === index)
      tab.classList.toggle("bg-space-800", i !== index)
      tab.classList.toggle("text-[#919193]", i !== index)
      tab.setAttribute("aria-selected", i === index)
    })

    // Show/hide corresponding panels
    this.panelTargets.forEach((panel, i) => {
      panel.classList.toggle("hidden", i !== index)
      panel.setAttribute("aria-hidden", i !== index)
    })
  }
}