import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const formatNumber = (num: number | null | undefined): string => {
  if (num === null || num === undefined) {
    return '0.00'
  }
  return num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const orderTypes = [
  { label: 'Draft', value: 'draft' },
  { label: 'Quote', value: 'quote' },
  { label: 'Retail Order', value: 'retail_order' },
  { label: 'B2B Order', value: 'b2b_order' },
  { label: 'Builder Order', value: 'builder_order' },
  { label: 'Marketplace Order', value: 'marketplace_order' }
] as const;

export const orderSubTypes = [
  { label: 'Not Set', value: 'st_not_set' },
  { label: 'Showroom', value: 'showroom' },
  { label: 'Amazon', value: 'amazon' },
  { label: 'Walmart', value: 'walmart' },
  { label: 'Sears', value: 'sears' },
  { label: 'Wholesale', value: 'wholesale' }
] as const;

export const orderSource = [
  { label: 'Not Set', value: 'os_not_set' },
  { label: 'Online Order', value: 'online_order' },
  { label: 'Phone Order', value: 'phone_order' },
  { label: 'Showroom Order', value: 'showroom_order' }
] as const;

export const orderQueue = [
  { label: 'New Orders', value: 'new_orders' },
  { label: 'Quotes', value: 'quotes_queue' },
  { label: 'Processing', value: 'processing_queue' },
  { label: 'Ready to Ship', value: 'ready_to_ship' },
  { label: 'Pending Dropship', value: 'pending_dropship' },
  { label: 'Picked Orders', value: 'picked_orders' },
  { label: 'Done Orders', value: 'done_orders' },
  { label: 'Problem Orders', value: 'problem_order' },
  { label: 'Billing Issue', value: 'billing_issue' },
  { label: 'Back Order', value: 'back_order' },
  { label: 'Revision', value: 'revision' },
  { label: 'Pending Refund', value: 'pending_refund' }
] as const;

// Types for type safety
export type OrderType = typeof orderTypes[number]['value'];
export type OrderSubType = typeof orderSubTypes[number]['value'];
export type OrderSource = typeof orderSource[number]['value'];
export type OrderQueue = typeof orderQueue[number]['value'];