import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["jobContainer"]
  static values = { url: String }

  connect() {
    this.fetchProgress()
    this.intervalId = setInterval(() => this.fetchProgress(), 5000)
  }

  disconnect() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  async fetchProgress() {
    try {
      const response = await fetch(this.urlValue, {
        headers: {
          'Accept': 'application/json'
        }
      })
      if (!response.ok) throw new Error("Network response was not ok")
      const data = await response.json()
      this.renderProgress(data)
    } catch (error) {
      console.error("Error fetching progress:", error)
    }
  }

  renderProgress(jobProgress) {
    if (Object.keys(jobProgress).length === 0) {
      this.jobContainerTarget.innerHTML = '<p class="text-space-300">No active jobs at the moment.</p>'
      return
    }

    let html = ''
    for (const [jobClass, progress] of Object.entries(jobProgress)) {
      const statusClass = this.getStatusClass(progress.status)
      html += `
        <div data-job-class="${jobClass}" class="mb-4">
          <h3 class="text-lg font-semibold text-space-100 mb-2">${progress.job_name}</h3>
          <div class="w-full bg-space-700 rounded-full h-2.5">
            <div class="bg-blue-300 h-2.5 rounded-full" style="width: ${progress.percentage}%"></div>
          </div>
          <p class="mt-2 text-sm text-space-300">
            ${progress.processed} / ${progress.total} processed (${progress.percentage}%)
          </p>
          <p class="mt-1 text-sm ${statusClass}">
            Status: ${progress.status}
            ${progress.error ? ` - Error: ${progress.error}` : ''}
          </p>
          <p class="text-xs text-space-400">Last updated: ${this.formatAge(progress.age)}</p>
          <button class="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                  data-action="click->progress#cancelJob"
                  data-job-class="${jobClass}"
                  data-job-id="${progress.jid}"
                  ${['completed', 'failed', 'canceled'].includes(progress.status) ? 'disabled' : ''}>
            ${['completed', 'failed', 'canceled'].includes(progress.status) ? 'Job Ended' : 'Cancel Job'}
          </button>
        </div>
      `
    }
    this.jobContainerTarget.innerHTML = html
  }

  getStatusClass(status) {
    switch(status) {
      case 'completed': return 'text-green-500'
      case 'failed': return 'text-red-500'
      case 'canceled': return 'text-gray-500'
      default: return 'text-yellow-500'
    }
  }

  formatAge(seconds) {
    if (seconds < 60) return `${Math.floor(seconds)} seconds ago`
    if (seconds < 3600) return `${Math.floor(seconds / 60)} minutes ago`
    return `${Math.floor(seconds / 3600)} hours ago`
  }

  async cancelJob(event) {
    const jobId = event.currentTarget.dataset.jobId
    try {
      const response = await fetch(`/api/v1/monitor/progress/${jobId}/cancel`, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) throw new Error("Failed to cancel job");
      event.currentTarget.disabled = true;
      event.currentTarget.textContent = "Canceling...";
    } catch (error) {
      console.error("Error canceling job:", error);
    }
  }
}