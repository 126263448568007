import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["canvas"]

  connect() {
    this.ctx = this.canvasTarget.getContext('2d')
    this.resizeCanvas()
    this.initNodes()
    this.animate()

    window.addEventListener('resize', this.resizeCanvas.bind(this))
  }

  disconnect() {
    window.removeEventListener('resize', this.resizeCanvas.bind(this))
  }

  resizeCanvas() {
    this.canvasTarget.width = window.innerWidth
    this.canvasTarget.height = window.innerHeight
    this.initNodes()
  }

  initNodes() {
    this.nodes = []
    const nodeCount = Math.floor((this.canvasTarget.width * this.canvasTarget.height) / 10000)
    for (let i = 0; i < nodeCount; i++) {
      this.nodes.push({
        x: Math.random() * this.canvasTarget.width,
        y: Math.random() * this.canvasTarget.height,
        vx: (Math.random() - 0.5) * 0.5,
        vy: (Math.random() - 0.5) * 0.5
      })
    }
  }

  animate() {
    this.ctx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)
    this.updateNodes()
    this.drawConnections()
    requestAnimationFrame(this.animate.bind(this))
  }

  updateNodes() {
    this.nodes.forEach(node => {
      node.x += node.vx
      node.y += node.vy

      if (node.x < 0 || node.x > this.canvasTarget.width) node.vx *= -1
      if (node.y < 0 || node.y > this.canvasTarget.height) node.vy *= -1
    })
  }

  drawConnections() {
    this.ctx.strokeStyle = 'rgba(255, 255, 255, 0.1)'
    this.ctx.lineWidth = 0.5

    for (let i = 0; i < this.nodes.length; i++) {
      for (let j = i + 1; j < this.nodes.length; j++) {
        const dx = this.nodes[i].x - this.nodes[j].x
        const dy = this.nodes[i].y - this.nodes[j].y
        const distance = Math.sqrt(dx * dx + dy * dy)

        if (distance < 100) {
          this.ctx.beginPath()
          this.ctx.moveTo(this.nodes[i].x, this.nodes[i].y)
          this.ctx.lineTo(this.nodes[j].x, this.nodes[j].y)
          this.ctx.stroke()
        }
      }
    }
  }
}