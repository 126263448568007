import React from 'react'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"

interface CustomItemFormData {
  sku: string
  title?: string
  brand?: string
  quantity: number
  price: number
  cost: number
}

interface CustomItemResult extends CustomItemFormData {
  id: number
  image?: string
  is_custom: boolean
  custom_title?: string
  custom_brand?: string
}

interface CustomItemProps {
  onAddItem: (item: CustomItemResult) => void
  disabled?: boolean
}

const CustomItem: React.FC<CustomItemProps> = ({ onAddItem, disabled = false }) => {
  const [formData, setFormData] = React.useState<CustomItemFormData>({
    sku: '',
    title: '',
    brand: '',
    quantity: 1,
    price: 0,
    cost: 0
  })

  const [open, setOpen] = React.useState<boolean>(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation() // Stop event from bubbling up to parent form

    if (!formData.sku.trim()) {
      return // Don't submit if SKU is empty
    }

    const customItem: CustomItemResult = {
      ...formData,
      id: -Date.now(),
      image: '/api/placeholder/96/96',
      quantity: parseInt(formData.quantity.toString()),
      price: parseFloat(formData.price.toString()),
      cost: parseFloat(formData.cost.toString()),
      is_custom: true,
    }

    onAddItem(customItem)
    setOpen(false)
    setFormData({
      sku: '',
      title: '',
      brand: '',
      quantity: 1,
      price: 0,
      cost: 0
    })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof CustomItemFormData) => {
    e.preventDefault() // Prevent any default actions
    const value = e.target.type === 'number' ? Number(e.target.value) : e.target.value
    setFormData(prev => ({ ...prev, [field]: value }))
  }

  // Handle button click separately from form submission
  const handleAddItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!formData.sku.trim()) {
      return
    }

    const customItem: CustomItemResult = {
      ...formData,
      id: -Date.now(),
      image: '/api/placeholder/96/96',
      quantity: parseInt(formData.quantity.toString()),
      price: parseFloat(formData.price.toString()),
      cost: parseFloat(formData.cost.toString()),
      is_custom: true,
      custom_title: formData.title,
      custom_brand: formData.brand
    }

    onAddItem(customItem)
    setOpen(false)
    setFormData({
      sku: '',
      title: '',
      brand: '',
      quantity: 1,
      price: 0,
      cost: 0
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="secondary"
          disabled={disabled}
          className="bg-space-700 hover:bg-space-600"
        >
          Add Custom Item
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Add Custom Item</DialogTitle>
          <DialogDescription>
            Enter the details for a custom item not in the database.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="sku">SKU</Label>
              <Input
                id="sku"
                value={formData.sku}
                onChange={(e) => handleInputChange(e, 'sku')}
                className="bg-space-700 border-space-500"
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="quantity">Quantity</Label>
              <Input
                id="quantity"
                type="number"
                min="1"
                value={formData.quantity}
                onChange={(e) => handleInputChange(e, 'quantity')}
                className="bg-space-700 border-space-500"
                required
              />
            </div>
            <div className="space-y-2 col-span-2">
              <Label htmlFor="title">Title</Label>
              <Input
                id="title"
                value={formData.title}
                onChange={(e) => handleInputChange(e, 'title')}
                className="bg-space-700 border-space-500"
              />
            </div>
            <div className="space-y-2 col-span-2">
              <Label htmlFor="brand">Brand</Label>
              <Input
                id="brand"
                value={formData.brand}
                onChange={(e) => handleInputChange(e, 'brand')}
                className="bg-space-700 border-space-500"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="price">Price</Label>
              <Input
                id="price"
                type="number"
                min="0"
                step="0.01"
                value={formData.price}
                onChange={(e) => handleInputChange(e, 'price')}
                className="bg-space-700 border-space-500"
                required
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="cost">Cost</Label>
              <Input
                id="cost"
                type="number"
                min="0"
                step="0.01"
                value={formData.cost}
                onChange={(e) => handleInputChange(e, 'cost')}
                className="bg-space-700 border-space-500"
                required
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              type="button"
              onClick={handleAddItem}
              className="bg-green-500 text-white"
            >
              Add Item
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default CustomItem