// app/javascript/controllers/sidebar_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar", "collapsible", "collapsibleButton", "collapsibleIcon"]

  connect() {
    // Initialize collapsible states
    this.collapsibleTargets.forEach(collapsible => {
      const id = collapsible.dataset.id
      const isCollapsed = localStorage.getItem(`sidebar_${id}`) !== 'expanded'
      if (isCollapsed) {
        this.collapse(collapsible)
      } else {
        this.expand(collapsible)
      }
    })
  }

  toggle() {
    this.sidebarTarget.classList.toggle('-translate-x-full')
  }

  toggleCollapsible(event) {
    const collapsible = event.currentTarget.nextElementSibling
    const id = event.currentTarget.dataset.id
    if (collapsible.classList.contains('hidden')) {
      this.expand(collapsible)
      localStorage.setItem(`sidebar_${id}`, 'expanded')
    } else {
      this.collapse(collapsible)
      localStorage.setItem(`sidebar_${id}`, 'collapsed')
    }
  }

  expand(collapsible) {
    collapsible.classList.remove('hidden')
    collapsible.previousElementSibling.querySelector('.collapse-icon').classList.add('rotate-180')
  }

  collapse(collapsible) {
    collapsible.classList.add('hidden')
    collapsible.previousElementSibling.querySelector('.collapse-icon').classList.remove('rotate-180')
  }
}